import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Team = lazy(() => import("./pages/Team"));

const Routes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/team",
      element: <Team />,
    },
  ]);

  return routes;
};

export default Routes;
