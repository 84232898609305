import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Center, Spinner, Box, useColorModeValue } from "@chakra-ui/react";

import Routes from "./Routes";

const SuspenseFallback = () => (
  <Center minH={"100vh"}>
    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand_pink" size="xl" />
  </Center>
);

const App = () => {
  const bgColor = useColorModeValue("brand_pink", "black");

  return (
    <Box minH={"100vh"} bg={bgColor}>
      <Router>
        <Suspense fallback={<SuspenseFallback />}>
          <Routes />
        </Suspense>
      </Router>
    </Box>
  );
};

export default App;
