import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

// Color mode config
const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: "Degular",
      },
    },
  },
  fonts: {
    body: "Degular",
    heading: "Degular-display",
  },
  colors: {
    brand_pink: "#F8E4D4",
  },
  breakpoints,
  config,
});

export default theme;
